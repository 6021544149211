import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import nakkiran from "../../images/about/our-leader-team/Nakkiran Saravanakumar.webp"
import ratna from "../../images/career-images/ratna-sosialin.webp"
import carl from "./../../images/career-images/christopher-carl.webp"
import catherine from "../../images/career-images/catherine.webp"
import yadil from "../../images/career-images/yadil.webp"
import fendy from "../../images/career-images/fendy.webp"
import nur from "../../images/career-images/nur-azizah.webp"
import camel from "../../images/career-images/camel.webp"
import steven from "../../images/career-images/steven.webp"
import bella from "../../images/career-images/bella.png"
import anne from "../../images/career-images/anne.webp"
import langgeng from "../../images/career-images/langgeng.png"
import jessica from "../../images/career-images/jessica-sunardi.webp"
import anton from "../../images/career-images/anton.webp"
import vincent from "../../images/career-images/vincent.webp"
import yuwono from "../../images/career-images/yuwono.webp"
import nadya from "../../images/career-images/nadya.png"

const content = [
  {
    image: ratna,
    author: "Ratna Sosialin",
    designation: "Ex Quality Senior GM",
    jabatan: "Ex Quality Senior GM",
    jabatanCN: "前质量部高级总经理",
    description:
      "Majority employees are young people graduated from local and overseas universities who are IT literate and motivated.",
    deskripsi:
      "Karyawan mayoritas adalah anak muda lulusan universitas dalam dan luar negeri yang melek IT dan penuh motivasi.",
  },
  {
    image: carl,
    author: "Christopher Carl Sweeney",
    designation: "Ex Associate Director",
    jabatan: "Ex Associate Director",
    jabatanCN: "前副董事",
    description:
      "Kalbio success in building the highest quality possible is mainly because of the team’s “can do” attitude. They rose to every challenge and often exceeded the required standards. I highly recommend Kalbio team to anyone looking for a technical, fully GMP compliant partner.",
    deskripsi:
      "Kalbio berhasil membangun kualitas yang setinggi mungkin terutama dikarenakan sikap “bisa melakukan” dari tim. Mereka menghadapi setiap tantangan dan sering melebihi standar yang dibutuhkan. Saya merekomendasi tim Kalbio kepada yang mencari mitra teknis dan mematuhi GMP sepenuhnya.",
  },
  {
    image: catherine,
    author: "Catherine Lesmana",
    designation: "Ex Production Scientist",
    jabatan: "Ex Scientist Produksi",
    jabatanCN: "前生产科学家",
    description:
      "Working for Kalbio had been a life changing experience for me. Everyday there were new challenges to face, new knowledge to gain, and new friendships made. Of course, solid teamwork made it all possible.",
    deskripsi:
      "Bekerja untuk Kalbio telah menjadi pengalaman yang mengubah hidup saya. Setiap hari ada tantangan baru yang harus dihadapi, pengetahuan baru yang didapat, dan pertemanan baru yang tercipta. Tentu saja, kerja sama tim yang solid membuat semuanya menjadi mungkin.",
  },
  {
    image: yadil,
    author: "Yadil Andi Beddu",
    designation: "Ex HRGA Head",
    jabatan: "Ex Kepala HRGA",
    jabatanCN: "前人力资源与总务主管",
    description:
      "I have been working for Kalbio for almost five years now. It is a great honor to work with a group of such a dynamic, smart and young talents in Biopharmaceutical and Biotechnology",
    deskripsi:
      "Saya telah bekerja untuk kalbio selama hampir lima tahun sekarang. Merupakan suatu kehormatan besar untuk bekerja dengan sekelompok talenta yang dinamis, cerdas, dan muda di bidang biofarmasi dan bioteknologi.",
  },
  {
    image: fendy,
    author: "Aditya Fendy Heryanto",
    designation: "Production (DS) Manager",
    jabatan: "Manajer Produksi (DS)",
    jabatanCN: "生产经理（Drug Substances）",
    description:
      "A lot of new things can be learned here. The daily work can also be quite challenging. However, I joined an energetic team that enables me to excel myself and I genuinely enjoy working here.",
    deskripsi:
      "Banyak hal baru yang bisa dipelajari di sini. Pekerjaan sehari-hari juga bisa sangat menantang. Namun, saya bergabung dengan tim energik yang memungkinkan saya untuk mengungguli diri saya sendiri dan saya benar-benar menikmati bekerja di sini.",
  },
  {
    image: nur,
    author: "Nur Azizah Sitompul",
    designation: "Ex HR Dept Intern from Indonesian University",
    jabatan: "Ex Magang dept. HR dari Universitas Indonesia",
    jabatanCN: "来自印度尼西亚大学的前人力资源部实习生",
    description: `It's such a wonderful time working here, I gained a lot of knowledge & hands-on experience from the experts! I had the opportunity to be creative, make autonomous decisions, and hone my soft skills. Kalbio has a supportive & encouraging environment.`,
    deskripsi:
      "Sungguh adalah waktu yang menyenangkan bekerja di sini, saya mendapatkan banyak pengetahuan & pengalaman langsung dari para ahli! Saya memiliki kesempatan untuk menjadi kreatif, membuat keputusan mandiri, dan mengasah soft skill saya. Kalbio memiliki lingkungan yang suportif & menyemangati.",
  },
  {
    image: camel,
    author: "Camelia Evi Salamah",
    designation: "Ex PPIC Supervisor",
    jabatan: "Ex Supervisor PPIC",
    jabatanCN: "前生产计划库存控制主管",
    description:
      "Working with many talented people in KGM has been a pleasure for me. I got to experience many extraordinary things with them and learned a lot.",
    deskripsi:
      "Bekerja dengan banyak orang berbakat di KGM merupakan kegembiraan bagi saya. Saya dapat mengalami banyak hal yang luar biasa bersama mereka serta belajar banyak.",
  },
  {
    image: steven,
    author: "Steven Amadeus",
    designation: "IT Manager",
    jabatan: "Manajer IT",
    jabatanCN: "信息技术经理",
    description: `Kalbio gives me the opportunity to learn and grow along with the company. I learn lots of technical, managerial, and other soft skills. Kalbio's team is young, motivated, and helpful.`,
    deskripsi:
      "Kalbio memberi saya kesempatan untuk belajar dan bertumbuh bersama perusahaan. Saya belajar banyak keahlian teknis, manajerial, dan soft skill lainnya. Tim kalbio masih muda, penuh motivasi, dan sangat membantu.",
  },
  {
    image: bella,
    author: "Bella Anbar Fauzziyah",
    designation: "Production Supervisor",
    jabatan: "Supervisor Produksi",
    jabatanCN: "生产主管",
    description: `Kalbio is a great company to work for, supported by the very high standards set by Kalbio giving me the opportunity to thrive and develop within the role.`,
    deskripsi:
      "Kalbio adalah perusahaan yang hebat untuk bekerja, didukung oleh standar yang sangat tinggi yang ditetapkan oleh Kalbio yang memberi saya kesempatan untuk tumbuh dan berkembang dalam peran tersebut.",
  },
  // {
  //   image: anne,
  //   author: "Anne Gabriella Sondakh",
  //   designation: "Production Supervisor",
  //   jabatan: "Supervisor Produksi",
  //   jabatanCN: "生产主管",
  //   description: `Jumping into a working environment where everyone is willing to share not only their knowledge and experiences but also their kindness and friendship have been a privilege I never thought I could experience before.`,
  //   deskripsi:
  //     "Terjun ke lingkungan kerja di mana setiap orang bersedia untuk berbagi tidak hanya pengetahuan dan pengalaman tetapi juga kebaikan dan persahabatan mereka telah menjadi hak istimewa yang tidak pernah saya pikir dapat saya alami sebelumnya.",
  // },
  {
    image: langgeng,
    author: "Gregorius Agung Langgeng",
    designation: "Production Supervisor",
    jabatan: "Supervisor Produksi",
    jabatanCN: "生产主管",
    description: `KGM is the perfect place for those who thrive on challenges and personal growth. Everyone's support has been amazing, and I'm grateful to be part of such a kind group of individuals who keeps me inspired to improve and excel.`,
    deskripsi:
      "KGM adalah tempat yang tepat bagi mereka yang mampu menghadapi tantangan dan mengembangkan diri. Dukungan dari semua orang sungguh luar biasa, dan saya bersyukur menjadi bagian dari kelompok orang baik yang membuat saya terus terinspirasi untuk berkembang dan berprestasi.",
  },
  {
    image: jessica,
    author: "Jessica Sunardi",
    designation: "Ex R&D Dept Intern from Pelita harapan University",
    jabatan: "Ex Magang dept. R&D dari Universitas Pelita Harapan",
    jabatanCN: "来自Pelita Harapan 大学的前研发部实习生",
    description: `The best thing about this internship is the hands-on experience when carrying out laboratory works. The skills and knowledge I gained are immeasurable. I couldn’t have asked for a better first internship experience.`,
    deskripsi:
      "Hal terbaik tentang magang ini adalah pengalaman langsung saat melakukan pekerjaan laboratorium. Keterampilan dan pengetahuan yang saya peroleh tidak terukur. Saya tidak bisa meminta pengalaman magang pertama yang lebih baik.",
  },
  {
    image: anton,
    author: "Anton Suwandoro",
    designation: "Engineering Manager",
    jabatan: "Manajer Engineering",
    jabatanCN: "工程经理",
    description: `Having been working for KGM for 7 years has developed my technical skill a lot. So challenging as KGM was the first biotechnology facility in Indonesia. I grow with KGM. It’s Fun, Family and Proud.`,
    deskripsi:
      "Bekerja untuk KGM selama 7 tahun telah mengembangkan banyak keterampilan teknis saya. Sangatlah menantang mengingat KGM adalah fasilitas bioteknologi pertama di Indonesia. Saya tumbuh bersama KGM. Adalah menyenangkan, keluarga dan bangga.",
  },
  {
    image: vincent,
    author: "Vincent Cunardy",
    designation: "IT Application Officer",
    jabatan: "Officer IT Application",
    jabatanCN: "资讯科技应用员工",
    description: `Working at Kalbio is a worthwhile experience. Here I was given an opportunity to partake in a big project, where I could expand my knowledge, skill, and connection in the comfortable working environment.`,
    deskripsi:
      "Bekerja di kalbio adalah pengalaman yang berharga. Di sini saya diberi kesempatan untuk ikut ambil bagian dalam proyek besar, di mana saya dapat memperluas pengetahuan, keterampilan, dan koneksi saya di lingkungan kerja yang nyaman.",
  },
  {
    image: yuwono,
    author: "Yuwono Wibowo",
    designation: "Ex Production Supervisor",
    jabatan: "Ex Supervisor Produksi",
    jabatanCN: "前生产主管",
    description: `It's been a privilege working in one of the pioneer of biopharmaceutical industry in Indonesia. The learning curve is exponential as you are exposed to state of the art industrial biotech equipment, plus the working environment is fun!`,
    deskripsi:
      "Merupakan suatu kehormatan dapat bekerja di salah satu pionir industri biofarmasi di Indonesia. Kurva belajarnya eksponensial karena berhadapan langsung dengan peralatan industri biotek yang canggih, ditambah lingkungan kerjanya menyenangkan!",
  },
  {
    image: nadya,
    author: "Nadya Novyani",
    designation: "QSC Supervisor",
    jabatan: "Supervisor QSC",
    jabatanCN: "质量体系与合规主管",
    description: `Kalbio is the right place to develop and learn science in biotechnology area.  The workplace and environment are comfortable. Many young and competent people at kalbio who make kalbio more advanced.`,
    deskripsi:
      "Kalbio adalah tempat yang tepat untuk mengembangkan dan mempelajari sains di area bioteknologi. Area kerja dan lingkungannya nyaman. Banyak anak-anak muda dan kompeten yang membuat Kalbio lebih berkembang.",
  },
]

export default content
